import Vue from 'vue';
import VueRouter from 'vue-router';

import Live from "../pages/Live";
import UCenter from "../pages/UCenter";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home"
    },
    {
        path: "/:id",
        name: "Live",
        component: Live,
        redirect: {name: "Live.Index"},
        children: require("./live").default,
    },
    {
        path: "/ucenter",
        name: "UCenter",
        component: UCenter,
        redirect: {name: "UCenter.Index"},
        children: require("./ucenter").default,
    }
];

let router = new VueRouter({
    routes: routes,
    mode: "history"
});

router.beforeEach((to, from, next) => {
    if (to.name === 'Home')
        window.location.replace("http://m.mlszp.com");
    else next();
});

export default router

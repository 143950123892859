<template>
    <div class="index">
        <NavBar title="个人中心"/>
        <van-tabs>
            <van-tab title="打赏">
                <Reward ref="reward" :height="contentHeight"/>
            </van-tab>
            <van-tab title="收藏">
                <Star ref="star" :width="width" :height="contentHeight"/>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import NavBar from "./comps/NavBar";
    import Reward from "./comps/Reward";
    import Star from "./comps/Star";

    export default {
        name: "Index",
        props: {
            width: Number,
            height: Number,
        },
        computed: {
            contentHeight() {
                return this.height - 88;
            }
        },
        components: {Star, Reward, NavBar},
    }
</script>

<style scoped lang="less">
    .index {
        padding-top: 43px;

        .van-tab {
            .van-tab__text {
                font-weight: bold;
            }
        }
    }
</style>

<template>
    <div :style="{height:height+'px'}" style="overflow-y: scroll;padding: 0 10px;">
        <LoadingList :load-func="load" empty-text="暂无收藏照片">
            <van-row slot-scope="{ result }" :gutter="10">
                <van-col span="6" v-for="star in result.data" :key="star.id">
                    <ResourceImg :id="star['photo']['thumb_id']" resize="m_8,w_120,h_120" :height="imgHeight"/>
                </van-col>
            </van-row>
        </LoadingList>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import LoadingList from "../../../comps/LoadingList";
    import ResourceImg from "../../../comps/ResourceImg";

    export default {
        name: "Star",
        components: {ResourceImg, LoadingList},
        props: {
            width: Number,
            height: Number,
        },
        computed: {
            imgHeight() {
                return (this.width - 50) / 4;
            }
        },
        methods: {
            load(page_no) {
                let args = {
                    page_no: page_no,
                    page_size: 60,
                };
                return api.v1.star.search(args);
            }
        }
    }
</script>

<style scoped lang="less">
    img {
        width: 100%;
        margin-top: 10px;
        display: block;
        background: #ffffff;
        border-radius: 5px;
    }
</style>

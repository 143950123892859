import Index from "../pages/ucenter/Index";
import Star from "../pages/ucenter/comps/Star";
import Reward from "../pages/ucenter/comps/Reward";

export default [
    {
        path: "index",
        name: "UCenter.Index",
        component: Index,
    },
    {
        path: "star",
        name: "UCenter.Star",
        component: Star,
    },
    {
        path: "reward",
        name: "UCenter.Reward",
        component: Reward,
    }
];

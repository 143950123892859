import api from "../utils/api";
import wx from 'weixin-js-sdk';
import func from "../utils/func";

let cached = {}; // 配置缓存

export default {
    data() {
        return {
            url: window.location.href,
            flag: "ingsports-official-account-service",
        }
    },
    created() {
        if (!func.isDefined(cached[this.url])) {
            cached[this.url] = {};
            api.v1.wechat.jsapi(this.flag, this.url)
                .then(res => {
                    wx.config({
                        debug: false,
                        appId: res.app_id,
                        timestamp: res.timestamp,
                        nonceStr: res.nonce_str,
                        signature: res.signature,
                        jsApiList: [
                            'onMenuShareAppMessage',
                            'onMenuShareTimeline',
                            'onMenuShareQQ',
                            'onMenuShareWeibo',
                        ]
                    });
                });
        }
    },
    methods: {
        pay(args, success) {
            wx.ready(() => wx.chooseWXPay({
                package: args.package,
                paySign: args.paySign,
                nonceStr: args.nonceStr,
                signType: args.signType,
                timestamp: args.timeStamp,
                success: success
            }));
        },
        share(config, success) {
            const self = this;
            wx.ready(() => {
                wx.onMenuShareQQ(self.shareConfig("onMenuShareQQ", config, success));
                wx.onMenuShareWeibo(self.shareConfig("onMenuShareWeibo", config, success));
                wx.onMenuShareTimeline(self.shareConfig("onMenuShareTimeline", config, success));
                wx.onMenuShareAppMessage(self.shareConfig("onMenuShareAppMessage", config, success));
            });
        },
        shareConfig(flag, config, success) {
            const self = this;
            return {
                title: config["title"],
                desc: config["desc"],
                link: self.url, type: "link",
                imgUrl: func.imageUrl(config["cover"], {
                    resize: "m_8,w_300,h_300"
                }),
                success: () => success(flag, self.url),
            }
        }
    }
}

<template>
    <van-swipe
        v-if="items.length"
        indicator-color="#fe5900" :width="width" :height="height" :loop="false">
        <van-swipe-item v-for="(item, index) in items" :key="index">
            <a v-if="item.config['link']" :href="item.config['link']" target="_blank">
                <ResourceImg :id="item.config['rs_id']" resize="m_8,w_720,h_360" :width="width" :height="height"/>
            </a>
            <ResourceImg v-else :id="item.config['rs_id']" resize="m_8,w_720,h_360" :width="width" :height="height"/>
        </van-swipe-item>
    </van-swipe>
</template>

<script>
    import func from "../../../utils/func";
    import ResourceImg from "../../../comps/ResourceImg";

    export default {
        name: "Banner",
        components: {ResourceImg},
        props: {items: Array},
        computed: {
            width() {
                return func.maxBodyWith();
            },
            height() {
                return this.width / 2;
            }
        }
    }
</script>

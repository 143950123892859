<template>
    <van-overlay class="loading" :show="true">
        <img src="//m.mlszp.com/img/logo2.png" alt=""/>
        <van-loading size="110px" color="#fe5900"/>
    </van-overlay>
</template>

<script>
    export default {
        name: "Loading",
    }
</script>

<style scoped lang="less">
    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        max-width: 720px;
        z-index: 10000;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;

        img {
            width: 65px;
            position: absolute;
        }

        circle {
            stroke-width: 1;
        }
    }
</style>

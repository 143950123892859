<template>
    <div>
        <van-field
            v-model="value"
            :required="true" :readonly="true" right-icon="arrow"
            :label="label" :placeholder="title" @click="()=>this.show=true"/>
        <van-popup
            :close-on-click-overlay="false"
            v-model="show" position="bottom">
            <van-area
                :title="title"
                :area-list="this.areaList"
                @cancel="cancel" @confirm="confirm"/>
        </van-popup>
    </div>
</template>

<script>
    import api from "../utils/api";

    export default {
        props: {
            label: String,
            title: String
        },
        data() {
            return {
                show: false,
                areaList: {
                    province_list: {},
                    city_list: {},
                    county_list: {}
                }
            };
        },
        computed: {
            value: {
                get() {
                    return this.$attrs.value[1];
                },
                set(value) {
                    let code = "";
                    let names = [];
                    value.forEach(item => {
                        code = item.code;
                        names.push(item.name);
                    });
                    this.$emit("input", [code, names.join(" ")]);
                }
            }
        },
        mounted() {
            api.v1.area.all(3).then(res => {
                res.data.forEach((prov) => {
                    this.areaList.province_list[prov.code] = prov.name;
                    prov.children.forEach((city) => {
                        this.areaList.city_list[city.code] = city.name;
                        if (typeof city.children !== 'undefined') {
                            city.children.forEach((zone) => {
                                this.areaList.county_list[zone.code] = zone.name;
                            })
                        }
                    })
                })
            });
        },
        methods: {
            cancel() {
                this.show = false;
            },
            confirm(e) {
                this.value = e;
                this.show = false;
            }
        }
    };
</script>

<style scoped lang="less">
    .van-popup--bottom {
        max-width: 720px;
    }
</style>

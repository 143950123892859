<template>
  <van-form class="login-form">
    <van-field v-model="params.mobile" left-icon="phone" placeholder="请输入您的手机号码"/>
    <van-field v-model="params.password" left-icon="bag" placeholder="请输入短信验证码">
      <VueCodeButton slot="button" :disabled="codeDisabled" @click="sendCode"></VueCodeButton>
    </van-field>
    <div style="padding: 35px 16px 0 16px;">
      <SaveButton title="登录" :disabled="loginDisabled" :action="submit"/>
    </div>
  </van-form>
</template>

<script>
import api from "../utils/api";
import func from "../utils/func";
import enums from "../utils/enums";
import SaveButton from "./SaveButton";
import store from "../utils/store";

export default {
  name: "LoginForm",
  components: {SaveButton},
  data() {
    return {
      params: {
        mobile: "",
        password: "",
        platform: enums.Platform,
      },
      codeDisabled: true,
      loginDisabled: true,
    }
  },
  watch: {
    params: {
      deep: true,
      handler(nv) {
        this.codeDisabled = !func.isMobile(nv.mobile);
        this.loginDisabled = this.codeDisabled || nv.password === "";
      },
    }
  },
  methods: {
    async submit() {
      let args = await store.get("wechat.auth");
      await api.v1.auth.loginSubmit(
          args ? Object.assign(args, this.params) : this.params
      );
      this.$emit("done"); // trigger done event
    },
    sendCode() {
      return api.v1.auth.sendCode(this.params.mobile);
    },
  }
}
</script>

<style lang="less">
.login-form {
  background: #fff;
  border-radius: 4px;

  .van-cell {
    padding: 15px;

    .van-icon {
      font-size: 18px;
      margin-right: 10px;
    }
  }

  .vue-code-button {
    button {
      color: #ff5500;
      background: transparent;
    }
  }
}
</style>
<template>
    <SimpleLoading v-if="initLoading"/>
    <van-empty v-else-if="!result.total" :description="emptyText"/>
    <van-list v-else v-model="loading" :finished="finished" @load="load">
        <slot :result="result"></slot>
    </van-list>
</template>

<script>
    import DelayLoading from "./DelayLoading";
    import SimpleLoading from "./SimpleLoading";

    export default {
        name: "LoadingList",
        components: {SimpleLoading},
        mixins: [DelayLoading],
        props: {
            loadFunc: Function,
            emptyText: String,
        },
        data() {
            return {
                page_no: 0,
                result: {
                    total: 0,
                    data: [],
                },
                finished: false,
            }
        },
        computed: {
            initLoading() {
                return this.loading && this.page_no === 1;
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                const self = this;
                self.page_no += 1;
                this.withLoading(500, () => {
                    return self.loadFunc(self.page_no)
                        .then(res => {
                            self.result.total = res.total;
                            res.data.forEach(item => {
                                self.result.data.push(item);
                            });
                            self.finished = self.result.data.length === res.total;
                        });
                });
            },
            reload() {
                this.page_no = 0;
                this.result = {
                    total: 0,
                    data: [],
                };
                this.finished = false;
                this.load();
            }
        }
    }
</script>

<template>
    <div class="search">
        <van-row v-if="bibFind && faceFind" :gutter="10">
            <van-col span="10">
                <input v-model="number" class="bib-input" placeholder="请输入参赛号码"/>
            </van-col>
            <van-col span="6">
                <van-button @click="bibSearch" icon="search" type="primary" :loading="loading" :block="true">搜索</van-button>
            </van-col>
            <van-col span="8">
                <vue-cropper @cropped="faceSearch">
                    <div class="van-button van-button--primary van-button--normal van-button--block">
                        <div class="van-button__content">
                            <van-icon name="scan" class="van-button__icon"/>
                            <span class="van-button__text">人脸识别</span>
                        </div>
                    </div>
                </vue-cropper>
            </van-col>
        </van-row>
        <van-row v-else-if="faceFind" :gutter="2">
            <van-col span="14">
                <input v-model="number" class="bib-input" placeholder="未开通号码查询" disabled/>
            </van-col>
            <van-col span="2">
            </van-col>
            <van-col span="8">
                <vue-cropper @cropped="faceSearch">
                    <div class="van-button van-button--primary van-button--normal van-button--block">
                        <div class="van-button__content">
                            <van-icon name="scan" class="van-button__icon"/>
                            <span class="van-button__text">人脸识别</span>
                        </div>
                    </div>
                </vue-cropper>
            </van-col>
        </van-row>
        <van-row v-else-if="bibFind">
            <van-col span="18">
                <input v-model="number" class="bib-input" placeholder="请输入参赛号码"/>
            </van-col>
            <van-col span="6">
                <van-button @click="bibSearch" icon="search" type="primary" :loading="loading" :block="true">搜索</van-button>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import DelayLoading from "../../../comps/DelayLoading";

    export default {
        name: "Search",
        mixins: [DelayLoading],
        props: {album: Object},
        data() {
            return {
                number: "",
                bibFind: false,
                faceFind: false,
                bibs: {
                    total: 0,
                    data: [],
                },
                faces: {
                    total: 0,
                    data: [],
                },
            }
        },
        mounted() {
            this.bibFind = this.album.config.function["bib_find"];
            this.faceFind = this.album.config.function["face_find"];
        },
        methods: {
            bibSearch() {
                if (!this.number) {
                    return this.$notify({
                        type: "danger",
                        message: "请输入您的号码"
                    });
                }
                if (this.number.length > 12) {
                    return this.$notify({
                        type: "danger",
                        message: "您输入的参赛号码不正确"
                    });
                }
                this.doSearch({type: 1, value: this.number})
                    .then(() => this.number = "");
            },
            async faceSearch(data) {
                this.loading = true;
                let part = data.split(",");
                let args = {content: part[1], public: true};
                let res = await api.v1.resource.create(args);
                this.doSearch({type: 2, value: res.id});
            },
            doSearch(args) {
                const self = this;
                self.loaded = false; // reload
                return this.withLoading(500, () => {
                    return api.v1.album.search
                        .create(self.album.id, args);
                }).then(res => self.toMatch(res));
            },
            toMatch(item) {
                let params = {
                    id: this.album.id,
                    type: item.info.type,
                    value: item.info.value
                };
                this.$router.push({name: "Live.Match", params: params})
                    .catch(() => null); // 屏蔽同地址错误
            },
        }
    }
</script>

<style scoped lang="less">
    .search {
        color: #222;
        padding: 0 16px;

        .title {
            padding: 15px 0;
            font-size: 18px;
            text-align: center;
        }

        .bib-input {
            width: 100%;
            height: 40px;
            color: #000000;
            padding: 0 10px;
            border-color: #fe5900;
        }

        .van-button {
            padding: 0;
            font-size: 12px;
            border-radius: 0;
            line-height: 44px;
        }
    }
</style>

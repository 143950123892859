import Index from "../pages/live/Index";
import Match from "../pages/live/Match";

export default [
    {
        path: "",
        name: "Live.Index",
        component: Index,
    },
    {
        path: ":type/:value",
        name: "Live.Match",
        component: Match,
    }
]

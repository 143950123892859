import Vue from "vue"
import Vuex from "vuex";

import area from "./area";
import user from "./user";
import photo from "./photo";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        area,
        user,
        photo,
    }
});

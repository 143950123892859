import { render, staticRenderFns } from "./Book.vue?vue&type=template&id=ef9ef282&scoped=true&"
import script from "./Book.vue?vue&type=script&lang=js&"
export * from "./Book.vue?vue&type=script&lang=js&"
import style0 from "./Book.vue?vue&type=style&index=0&id=ef9ef282&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef9ef282",
  null
  
)

export default component.exports
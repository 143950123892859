<template>
    <van-loading v-if="loading" size="50px" type="spinner" color="#fe5900"/>
    <img v-else :src="url" :style="{opacity: transparent/100}" v-on="$listeners" v-bind="$attrs" alt=""/>
</template>

<script>
    import func from "../utils/func";

    export default {
        name: "ResourceImg",
        props: {
            id: {
                type: String,
                required: true
            },
            crop: String,
            resize: String,
            rotate: Number,
            loading: Boolean,
            transparent: {
                type: Number,
                default: 100,
            },
        },
        computed: {
            url() {
                let args = {
                    crop: this.crop,
                    resize: this.resize,
                    rotate: this.rotate
                };
                return func.imageUrl(this.id, args);
            }
        }
    }
</script>

<template>
    <div class="logo-image">
        <img :src="src" @click.stop="click" alt=""/>
        <van-checkbox v-if="select.enable && (checked || !overflow)" v-model="checked" checked-color="#fe5900"/>
        mlszp
    </div>
</template>

<script>
    export default {
        name: "LogoImage",
        props: {
            id: String,
            src: String,
            select: {
                type: Object,
                default: () => {
                    return {
                        limit: 0,
                        enable: false,
                    }
                },
            },
            selected: {
                type: Array,
                default: () => {
                    return [];
                },
            },
        },
        computed: {
            checked: {
                get() {
                    return this.selected.indexOf(this.id) !== -1;
                },
                set(value) {
                    let index = this.selected.indexOf(this.id);
                    if (value && index === -1) {
                        if (this.overflow) return;
                        this.selected.push(this.id);
                    } else if (!value && index !== -1) {
                        this.selected.splice(index, 1);
                    }
                }
            },
            overflow() {
                return this.select.limit > 0 && this.selected.length >= this.select.limit;
            }
        },
        methods: {
            click(e) {
                if (!this.select.enable) this.$emit("click", e);
                else this.checked = !this.checked;
            }
        }
    }
</script>

<style lang="less">
    .logo-image {
        width: 100%;
        height: 100%;
        color: #fe5900;
        background: #ffffff;
        font-weight: bold;
        position: relative;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .van-checkbox {
            top: 5px;
            right: 5px;
            position: absolute;
        }

        .van-checkbox__icon .van-icon {
            border: 2px #fe5900 solid;
        }
    }
</style>

<template>
    <div :style="{height:height+'px'}" style="overflow-y: scroll;">
        <LoadingList :load-func="load" empty-text="暂无打赏记录">
            <template slot-scope="{ result }">
                <div v-for="reward in result.data" :key="reward.id" class="reward">
                    <div class="title"><van-icon name="fire"/> {{reward.album.name}}</div>
                    <div>
                        打赏金额：{{reward.price * reward.photos.length | formatCNF}}
                        <van-button @click="download(reward)" icon="down" size="mini" type="primary" style="float: right;">下载</van-button>
                    </div>
                    <div>
                        支付时间：{{reward["paid_at"] | tsFormat}}
                    </div>
                    <div>
                        交易单号：{{reward["out_tid"]}}
                    </div>
                    <van-row :gutter="10">
                        <van-col span="6" v-for="photo in reward.photos" :key="photo.id">
                            <ResourceImg :id="photo['thumb_id']" resize="m_8,w_120,h_120"/>
                        </van-col>
                    </van-row>
                </div>
            </template>
        </LoadingList>
        <RewardDown ref="download"/>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import func from "../../../utils/func";
    import RewardDown from "./RewardDown";
    import LoadingList from "../../../comps/LoadingList";
    import ResourceImg from "../../../comps/ResourceImg";

    export default {
        name: "Reward",
        components: {RewardDown, ResourceImg, LoadingList},
        props: {
            height: Number
        },
        data() {
            return {
                percent: 0,
            }
        },
        methods: {
            load(page_no) {
                let args = {
                    is_paid: 1,
                    page_no: page_no,
                    page_size: 10,
                };
                return api.v1.reward.search(args);
            },
            download(reward) {
                let photos = func.getByKey(reward.photos, "id");
                this.$refs.download.start(reward.album.id, reward.type, photos);
            }
        }
    }
</script>

<style scoped lang="less">
    .reward {
        margin: 10px;
        cursor: pointer;
        padding: 8px 12px;
        line-height: 20px;
        color: #999999;
        background: #fff;
        font-size: 12px;
        border-radius: 5px;
        display: block;

        .title {
            margin: 5px 0;
            color: #fe5900;
            font-size: 14px;
            font-weight: bold;

            .van-icon {
                float: left;
                margin: 2px 5px 0 0;
            }
        }

        img {
            float: left;
            margin: 5px 0;
            max-width: 100%;
            border-radius: 4px;
        }
    }
</style>

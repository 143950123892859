<template>
    <BottomPopup v-model="show" title="选择拼图模式" class="puzzle-type">
        <van-row>
            <van-col span="8" @click="click('gif')"><div>GIF</div> 动图</van-col>
            <van-col span="8" @click="click('grid')"><div><van-icon name="plus"/></div> 宫格</van-col>
            <van-col span="8" @click="click('simple')"><div><van-icon name="minus"/></div> 直拼</van-col>
        </van-row>
    </BottomPopup>
</template>

<script>
    import BottomPopup from "../../../comps/BottomPopup";

    export default {
        name: "PuzzleType",
        components: {BottomPopup},
        data() {
            return {
                show: false,
            }
        },
        methods: {
            open() {
                this.show = true;
            },
            click(type) {
                this.show = false;
                this.$emit("click", type);
            }
        }
    }
</script>

<style scoped lang="less">
    .puzzle-type {
        color: #333;
        height: 200px;
        font-size: 14px;

        .title {
            padding: 15px 0;
            font-size: 18px;
            text-align: center;
        }

        .van-row {
            margin-top: 20px;

            .van-col {
                cursor: pointer;
                text-align: center;
                line-height: 40px;

                div {
                    width: 60px;
                    height: 60px;
                    margin: 0 auto;
                    color: #fe5900;
                    background: #e8e8e8;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
</style>

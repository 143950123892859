<template>
  <router-view :width="width" :height="height" :visitor-id="visitorId"/>
</template>

<script>
import api from "./utils/api";
import func from "./utils/func";
import auth from "./utils/auth";
import enums from "./utils/enums";
import store from "./utils/store";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default {
  name: "App",
  data() {
    return {
      width: document.body.clientWidth,
      height: document.body.clientHeight,
      visitorId: "",
    }
  },
  async mounted() {
    const self = this;
    window.onresize = () => {
      self.width = document.body.clientWidth;
      self.height = document.body.clientHeight;
    };
    clearTimeout(window.DetectTimeId);
    await this.initVisitor();
    await this.wechatLogin();
  },
  methods: {
    async initVisitor() {
      const fpPromise = await FingerprintJS.load();
      this.visitorId = (await fpPromise.get()).visitorId;
    },
    async wechatLogin() {
      if (!func.isWeiXin()) return;
      let guest = await auth.isGuest();
      if (guest === false) return;
      let exist = await store.get("wechat.auth");
      if (exist && exist.auth_id && exist.signature) return;
      let userId = this.$route.query.user_id;
      let authId = this.$route.query.auth_id;
      let signature = this.$route.query.signature;
      if (authId && signature) {
        if (userId) {
          let args = {platform: enums.Platform};
          await api.v1.auth.loginSubmit(Object.assign(args, this.$route.query));
        } else {
          await store.save("wechat.auth", {
            auth_id: authId, signature: signature,
          });
        }
      } else {
        let ref = window.location.href;
        let res = await api.v1.auth.wechatRedirect(ref)
        window.location.href = res.url;
      }
    },
  },
}
</script>

<style lang="less">
.van-notify {
  min-height: 44px;
}

.van-nav-bar--fixed,
.van-sticky--fixed {
  max-width: 720px;
}
</style>

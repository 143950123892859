<template>
    <van-nav-bar class="ucenter-nav-bar" :left-arrow="true" :fixed="true" v-bind="$attrs" @click-left="back"/>
</template>

<script>
    export default {
        name: "NavBar",
        methods: {
            back() {
                let noPrev = this.$router.history._startLocation === this.$route.path;
                noPrev ? this.$router.replace({name: "UCenter.Index"}) : this.$router.back();
            }
        }
    }
</script>

<style lang="less">
    .ucenter-nav-bar {
        max-width: 720px;
        .van-nav-bar__left {
            font-weight: bold;

            .van-nav-bar__arrow {
                color: #323233;
                font-size: 18px;
            }
        }
    }
</style>

<template>
    <div v-if="photos.length">
        <VirtualCollection
            v-on="$listeners" v-bind="$attrs" @scroll.stop="onScroll"
            :style="scrollStyle" :collection="photos" :width="width" :height="height"
            :sectionSize="height*5" :cellSizeAndPositionGetter="cellSizeAndPositionGetter">
            <LogoImage slot="cell" slot-scope="props" @click="onPreview(props.data)" :key="props.data.id"
                       :id="props.data.id" :src="props.data.thumb.url" :select="select" :selected="selected"/>
        </VirtualCollection>
        <div v-if="loading" style="text-align: center; padding: 10px 0;">
            <van-loading type="spinner" size="18px" color="#969799">努力加载中</van-loading>
        </div>
        <!--图片预览-->
        <Preview ref="preview" :album="album" :photos="photos"/>
        <!--浮动功能-->
        <FixedFunc :album="album" :selected="selected" @select="onSelect"/>
    </div>
    <van-loading
        v-else-if="loading" type="spinner" style="margin-top:60px;"
        size="60px" color="#fe5900" :vertical="true">努力加载中
    </van-loading>
    <van-empty v-else image="search" description="暂无图片"/>
</template>

<script>
    import api from "../../../utils/api";
    import Preview from "./Preview";
    import FixedFunc from "./FixedFunc";
    import LogoImage from "../../../comps/LogoImage";
    import DelayLoading from "../../../comps/DelayLoading";

    export default {
        name: "Photos",
        mixins: [DelayLoading],
        components: {FixedFunc, Preview, LogoImage},
        props: {
            album: Object,
            width: Number,
            height: Number,
            columns: Number,
            padding: Number,
            params: Object,
            pageSize: Number,
            scrollable: Boolean,
        },
        data() {
            return {
                pageNo: 1,
                photos: [],
                offsetY: [],
                counter: 0,
                complete: false,
                select: {
                    limit: 0,
                    enable: false
                },
                selected: [],
            }
        },
        computed: {
            photoWidth() {
                let padding = (this.columns + 1) * this.padding;
                return (this.width - padding) / this.columns;
            },
            scrollStyle() {
                return this.scrollable ? "overflow-y:scroll;overflow-x:hidden;" : "overflow:hidden";
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                this.pageNo = 1;
                this.photos = [];
                this.counter = 0;
                this.loading = false;
                this.complete = false;
                for (let i = 0; i < this.columns; i++)
                    this.offsetY[i] = 0;
                this.loadPhotos();
            },
            onPreview(photo) {
                this.$refs.preview.open(photo.group, photo.index);
            },
            onSelect(enable, limit) {
                this.select.limit = limit;
                this.select.enable = enable;
                if (!enable) this.selected = [];
            },
            onScroll(e) {
                if (this.complete) return;
                let bottom = e.target.scrollHeight - e.target.scrollTop;
                if (bottom < e.target.clientHeight * 5) this.loadPhotos();
            },
            loadPhotos() {
                const self = this;
                if (this.loading) return;
                if (this.complete) return;
                this.withLoading(500, () => {
                    let args = Object.assign({}, this.params, {
                        page_no: this.pageNo,
                        page_size: this.pageSize,
                    });
                    return api.v1.album.photos
                        .search(this.album.id, args)
                        .then(res => {
                            self.pageNo += 1;
                            let oneGroup = [];
                            res.forEach((photo, index) => {
                                photo.index = index;
                                photo.group = self.photos.length;
                                oneGroup.push(this.preparePhoto(photo, this.counter++));
                            });
                            if (oneGroup.length === 0) self.complete = true;
                            else {
                                self.photos.push({group: oneGroup});
                                if (self.pageSize === 0) self.complete = true;
                            }
                        });
                });
            },
            preparePhoto(photo, index) {
                let columnIndex = index % this.columns;
                let photoHeight = (photo.height / photo.width) * this.photoWidth;
                this.offsetY[columnIndex] += photoHeight + this.padding;
                return {
                    data: photo,
                    width: this.photoWidth,
                    height: photoHeight,
                    x: columnIndex * (this.photoWidth + this.padding) + this.padding,
                    y: this.offsetY[columnIndex] - photoHeight
                }
            },
            cellSizeAndPositionGetter(item) {
                return {width: item.width, height: item.height, x: item.x, y: item.y}
            },
        }
    }
</script>

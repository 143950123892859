<template>
  <div class="album">
    <div class="name">{{ album.info.name }}</div>
    <van-row v-if="!hideStats">
      <van-col span="11">
        <van-icon name="photo-o"/>
        {{ album.stats["photo_release"] }} 张图片
      </van-col>
      <van-col span="11">
        <van-icon name="eye-o"/>
        {{ album.stats["visit_times"] }} 次浏览
      </van-col>
      <van-col span="2">
        <van-icon :name="iconName" @click="()=>this.more=!this.more" style="float: right;"/>
      </van-col>
    </van-row>
    <div v-if="more" style="margin-top:6px">
      <div>
        <van-icon name="location-o"/>
        {{ album.info.address.slice(1, 2).join(" ") }}
      </div>
      <div>
        <van-icon name="underway-o"/>
        {{ album.info.period[0] | tsFormat("YYYY-MM-DD") }} ~ {{ album.info.period[1] | tsFormat("YYYY-MM-DD") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Album",
  props: {album: Object},
  data() {
    return {
      more: false,
    }
  },
  computed: {
    iconName() {
      return this.more ? "arrow-up" : "arrow-down"
    },
    hideStats() {
      return this.album.config.function['stats_type'] == 1;
    },
  }
}
</script>

<style scoped lang="less">
.album {
  color: #777;
  line-height: 25px;
  font-size: 12px;
  background: #fff;
  padding: 8px 12px 0 12px;

  .name {
    color: #333;
    font-size: 16px;
    margin: 5px 0;
  }

  .van-icon {
    float: left;
    font-size: 16px;
    margin: 4px 8px 0 0;
  }
}
</style>

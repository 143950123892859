import api from "@basic/api";
import errs from "./errs";
import auth from "./auth";

let withToken = async (config) => {
   await auth.ready; // wait auth ready
   config.headers["x-user-id"] = await auth.getUserId();
   config.headers["x-user-key"] = await auth.getUserKey();
   config.headers["x-access-token"] = await auth.getToken();
   return config
};

let Visitor = api.http(process.env.VUE_APP_VISITOR, {before: withToken, failure: errs.commonErr});
let Support = api.http(process.env.VUE_APP_SUPPORT, {failure: errs.commonErr});
let Resource = api.http(process.env.VUE_APP_RESOURCE, {failure: errs.commonErr});

export default {
   v1: {
      area: {
         all: (level) => Support.get("v1/areas/all/" + level)
      },
      auth: {
         sendCode: (mobile) => Visitor.post("v1/auth/code", {mobile: mobile}),
         loginSubmit: (args) => auth.login(args),
         wechatRedirect: (refer) => Visitor.post("v1/auth/wechat/redirect", {refer: refer}),
         wechatQRCode: (scene) => Visitor.post("v1/auth/wechat/qr-code", {scene: scene}),
         wechatQRCodeProbe: (ticket) => Visitor.get(`v1/auth/wechat/qr-code/${ticket}`),
      },
      user: {
         detail: () => Visitor.get("v1/users"),
      },
      album: {
         mylist: (args) => Visitor.get(`v1/albums`, args),
         detail: (id, preview) => Visitor.get(`v1/albums/${id}`, {preview: preview}),
         photos: {
            search: (id, args) => Visitor.get(`v1/albums/${id}/photos`, args),
            hdURL: (id, photos) => Visitor.post(`v1/albums/${id}/photos/hd_url`, {ids: photos}),
            star: (id) => Visitor.get(`v1/albums/${id}/star-photos`),
            reward: (id, type) => Visitor.get(`v1/albums/${id}/reward-photos/${type}`)
         },
         search: {
            create: (id, args) => Visitor.post(`v1/albums/${id}/search/history`, args),
            history: (id, type) => Visitor.get(`v1/albums/${id}/search/history`, {type: type}),
            execute: (id, type, value) => Visitor.get(`v1/albums/${id}/search`, {type: type, value: value}),
         },
         persons: (id, args) => Visitor.get(`v1/albums/${id}/persons`, args),
         clue: (id, info) => Visitor.post(`v1/clues`, {album_id: id, info: info}),
         puzzle: {
            gif: (id, photoIds) => Visitor.post(`v1/albums/${id}/puzzles/gif`, {photos: photoIds, delay: 30}),
            grid: (id, photoIds) => Visitor.post(`v1/albums/${id}/puzzles/grid`, {photos: photoIds}),
            simple: (id, photoIds) => Visitor.post(`v1/albums/${id}/puzzles/simple`, {photos: photoIds})
         }
      },
      star: {
         search: (args) => Visitor.get("v1/stars", args),
         create: (args) => Visitor.post("v1/stars", args),
         delete: (id) => Visitor.delete(`v1/stars/${id}`),
      },
      reward: {
         search: (args) => Visitor.get("v1/rewards", args),
         create: (args) => Visitor.post("v1/rewards", args),
         probe: (id) => Visitor.get(`v1/rewards/${id}/probe`),
         toPay: (id, args) => Visitor.post(`v1/rewards/${id}/to-pay`, args),
      },
      download: {
         zip: (id) => Visitor.post(`v1/downloads/${id}/zip`),
         create: (args) => Visitor.post("v1/downloads", args),
         mpUrl: (id) => Visitor.get(`v1/downloads/${id}/mp-url`),
         mpCode: (id) => Visitor.get(`v1/downloads/${id}/mp-code`, {app_flag: "mlszp-visitor-miniprogram"})
      },
      wechat: {
         jsapi: (flag, url) => Visitor.post(`v1/jsapi/config`, {url: url, app_flag: flag}),
         redirect: (refer) => Visitor.get(``, {refer: refer}),
      },
      resource: {
         create: (args) => Resource.post("v1/resources", args),
         actionUrl: `${process.env.VUE_APP_RESOURCE}/v1/resources`,
         downloadUrl: (id, name) => `${process.env.VUE_APP_RESOURCE}/download/${id}?name=${name}`,
         downloadsProbe: (scene, id) => Resource.get(`v1/downloads/${scene}/${id}/probe`),
      },
      support: {
         qrCode: (text, size) => `${process.env.VUE_APP_SUPPORT}/qr-code?text=${text}&size=${size}`,
      }
   }
}

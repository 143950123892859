<template>
    <div class="match">
        <NavBar :title="title"/>
        <van-loading
            v-if="loading" type="spinner" style="padding: 100px 0"
            size="60px" color="#fe5900" :vertical="true">努力加载中
        </van-loading>
        <van-empty v-else-if="!photos.length" description="暂无匹配照片"/>
        <VirtualCollection
            v-else v-on="$listeners" v-bind="$attrs"
            style="overflow-x: hidden; overflow-y: scroll;"
            :collection="photos" :width="width" :height="height"
            :cellSizeAndPositionGetter="cellSizeAndPositionGetter">
            <LogoImage slot="cell" slot-scope="props" @click="onPreview(props.data)" :key="props.data.id"
                       :id="props.data.id" :src="props.data.thumb.url" :select="select" :selected="selected"/>
        </VirtualCollection>
        <!--图片预览-->
        <Preview ref="preview" :album="album" :photos="[{group: photos}]"/>
        <FixedFunc :album="album" :selected="selected" :download="true" :empty="!photos.length" @select="onSelect" @selectAll="onSelectAll"/>
    </div>
</template>

<script>
    import api from "../../utils/api";
    import FixedFunc from "./comps/FixedFunc";
    import DelayLoading from "../../comps/DelayLoading";
    import WechatJSSDK from "../../comps/WechatJSSDK";
    import LogoImage from "../../comps/LogoImage";
    import func from "../../utils/func";
    import Preview from "./comps/Preview";
    import log from "../../utils/log";
    import NavBar from "./comps/NavBar";

    export default {
        name: "Match",
        components: {NavBar, Preview, LogoImage, FixedFunc},
        mixins: [DelayLoading, WechatJSSDK],
        props: {album: Object},
        data() {
            return {
                photos: [],
                columns: 2,
                padding: 10,
                offsetY: [],
                select: {
                    limit: 0,
                    enable: false
                },
                selected: [],
            }
        },
        computed: {
            title() {
                return `共匹配 ${this.photos.length} 张图片`;
            },
            width() {
                return Math.min(document.body.clientWidth, 720);
            },
            height() {
                return document.body.clientHeight - 44;
            },
            photoWidth() {
                let padding = (this.columns + 1) * this.padding;
                return (this.width - func.getScrollWidth() - padding) / this.columns;
            }
        },
        mounted() {
            this.refresh();
            this.share({
                title: this.album["config"]["share"]["title"],
                desc: "嗨！小伙伴们速来围观，快看看我在人海中找到了谁^_^",
                cover: this.album["config"]["share"]["cover"],
            }, (flag, url) => {
                log.image("album.shared", {album_id: this.album.id, flag: flag, url: url});
            });
        },
        methods: {
            refresh() {
                for (let i = 0; i < this.columns; i++)
                    this.offsetY[i] = 0;
                this.loadPhotos();
            },
            goBack() {
                this.$router.replace({name: "Live.Index", params: {id: this.album.id}});
            },
            onPreview(photo) {
                this.$refs.preview.open(0, photo.index);
            },
            onSelect(enable, limit) {
                this.select.limit = limit;
                this.select.enable = enable;
                if (!enable) this.selected = [];
            },
            onSelectAll(enable) {
                if (!enable) this.selected = [];
                else this.selected = func.getByKey(this.photos, "id");
            },
            loadPhotos() {
                const self = this;
                const {type, value} = this.$route.params;
                this.withLoading(500, () => {
                    return api.v1.album.search.execute(this.album.id, type, value)
                        .then(res => {
                            res.forEach((photo, index) => {
                                photo.index = index;
                                self.photos.push(self.preparePhoto(photo, index))
                            });
                        });
                });
            },
            preparePhoto(photo, index) {
                let columnIndex = index % this.columns;
                let photoHeight = (photo.height / photo.width) * this.photoWidth;
                this.offsetY[columnIndex] += photoHeight + this.padding;
                return {
                    id: photo.id,
                    data: photo,
                    width: this.photoWidth,
                    height: photoHeight,
                    x: columnIndex * (this.photoWidth + this.padding) + this.padding,
                    y: this.offsetY[columnIndex] - photoHeight
                }
            },
            cellSizeAndPositionGetter(item) {
                return {width: item.width, height: item.height, x: item.x, y: item.y}
            },
        }
    }
</script>

<style scoped lang="less">
    .match {
        padding-top: 44px;
    }
</style>

import Vue from "vue";
import auth from "./auth";

export default {
    loginErr(err) {
        let sysCode = err.sys_code.toString();
        let errCode = err.err_code.toString();
        if (errCode === "403001" && sysCode === "1001") {
            Vue.prototype.$notify({message: "验证码错误 ，请核对！", type: "danger"});
        } else if (errCode.substr(0, 3) !== "401") {
            Vue.prototype.$notify({message: "系统繁忙 ，请稍后重试！", type: "danger"});
        }
        return Promise.reject(err);
    },
    commonErr(err) {
        let errCode = err.err_code.toString();
        if (errCode.substr(0, 3) === "401") {
            auth.refresh()
                .finally(async () => {
                    let guest = await auth.isGuest();
                    guest ? window.location.reload()
                        : auth.logout().finally(window.location.reload);
                });
        } else {
            Vue.prototype.$notify({message: "系统繁忙 ，请稍后重试！", type: "danger"});
        }
        return Promise.reject(err)
    },
    sendCodeErr(err) {
        Vue.prototype.$notify({message: "验证码发送失败，请稍后重试！", type: "danger"});
        return Promise.reject(err);
    }
}

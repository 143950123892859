<template>
  <div class="index" @scroll.passive="scroll">
    <div ref="header">
      <Banner :items="album['banners']"/>
      <Album :album="album"/>
    </div>
    <van-sticky>
      <Search ref="search" :album="album"/>
      <van-row v-if="folderItems.length > 1" class="folders">
        <van-col span="21">
          <ButtonTabs v-model="params.folder_id" @change="refresh" :options="folderItems"/>
        </van-col>
        <van-col span="3">
          <van-dropdown-menu :overlay="false">
            <van-dropdown-item
                @opened="()=>this.dropdown=true"
                @closed="()=>this.dropdown=false">
              <ButtonTabs class="btn-tabs" v-model="params.folder_id" @change="refresh" :options="folderItems"/>
            </van-dropdown-item>
          </van-dropdown-menu>
        </van-col>
      </van-row>
    </van-sticky>
    <Photos ref="photos" :album="album" :params="params" :page-size="120"
            :width="width" :height="height" :padding="10" :columns="2" :scrollable="scrollable"/>
  </div>
</template>

<script>
import log from "../../utils/log";
import Banner from "./comps/Banner";
import Search from "./comps/Search";
import Photos from "./comps/Photos";
import ButtonTabs from "../../comps/ButtonTabs";
import WechatJSSDK from "../../comps/WechatJSSDK";
import Album from "@/pages/live/comps/Album";

export default {
  name: 'Home',
  props: {
    album: Object,
    width: Number,
    height: Number,
  },
  mixins: [WechatJSSDK],
  components: {Album, Search, ButtonTabs, Photos, Banner},
  data() {
    return {
      params: {
        folder_id: "",
      },
      dropdown: false,
      scrollable: false,
    }
  },
  computed: {
    folderItems() {
      let folderItems = [{value: "", label: "全部"}];
      this.album['folders'].forEach(folder => {
        folderItems.push({value: folder.id, label: folder.info.name});
      });
      return folderItems;
    }
  },
  mounted() {
    this.share(this.album["config"]["share"], (flag, url) => {
      log.image("album.shared", {album_id: this.album.id, flag: flag, url: url});
    });
  },
  methods: {
    scroll(e) {
      this.scrollable = (e.target.scrollTop >= this.$refs.header.clientHeight);
    },
    refresh() {
      this.$refs.photos.refresh();
    }
  }
}
</script>

<style lang="less">
.index {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-x: hidden;

  .search {
    padding: 10px;
    background: #ffffff;

    .bib-input {
      width: 100%;
      height: 40px;
      color: #000000;
      padding: 0 10px;
      border-radius: 5px;
      border-color: #fe5900;
    }

    .van-button {
      padding: 0;
      font-size: 12px;
      border-radius: 5px;
      line-height: 44px;
    }
  }

  .folders {
    width: 100%;
    height: 37px;
    overflow: hidden;
    color: #323233;
    background: #ffffff;
    padding: 0 0 10px 0;

    .active {
      color: #ffffff;
      background: #FE5900;
    }

    button {
      color: #323233;
      margin: 5px 0 5px 10px;
      background: transparent;
    }

    .van-popup {
      width: 100%;
      background: #ffffff;
      margin-top: -47px;
      padding: 9px 5px 5px 5px;

      .btn-tabs {
        float: left;
        width: 87.5%;
      }
    }

    .van-dropdown-menu__bar {
      height: 37px;
      background: #ffffff;
      box-shadow: none;
    }

    .van-dropdown-menu__title {
      padding: 0;
    }

    .van-dropdown-menu__title::after {
      border-width: 5px;
    }

    .van-dropdown-item {
      margin: 0 auto;
      max-width: 720px;
      z-index: 2007;
    }
  }
}
</style>

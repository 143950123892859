<template>
    <div class="ucenter">
        <router-view v-if="user.id" :user="user" v-bind="$attrs"/>
        <van-empty v-else-if="guest" description="需登录后访问">
            <van-button @click="toHome" icon="home-o" type="default" :round="true">访问首页</van-button>
            <van-button @click="doLogin" icon="user-o" type="primary" :round="true">立即登录</van-button>
        </van-empty>
        <LoginPopup ref="loginPopup"/>
    </div>
</template>

<script>
    import auth from "../utils/auth";
    import LoginPopup from "../comps/LoginPopup";

    export default {
        name: "Ucenter",
        components: {LoginPopup},
        data() {
            return {
                user: {},
                guest: false,
            }
        },
        async mounted() {
            this.guest = await auth.isGuest();
            if (this.guest === false) {
                this.$store.dispatch("user/detail")
                    .then(res => this.user = res);
            }
        },
        methods: {
            toHome() {
                this.$router.replace({name: "Home"});
            },
            doLogin() {
                const self = this;
                this.$refs.loginPopup.open(() => {
                    self.$store.dispatch("user/detail", true)
                        .then(res => self.user = res);
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .ucenter {
        .van-button {
            margin: 0 10px;
        }
    }
</style>

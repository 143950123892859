import api from "../utils/api";

export default {
    namespaced: true,
    state: {
        stars: {}
    },
    actions: {
        async loadStars(ctx, id) {
            if (ctx.state.stars[id]) return ctx.state.stars[id];
            ctx.state.stars[id] = await api.v1.album.photos.star(id);
            return ctx.state.stars[id];
        }
    }
}

<template>
    <div v-if="isEnable">
        <div style="height: 4px;">
            <van-progress
                v-if="loading || failed" color="#fe5900"
                :percentage="percent" :pivot-text="pivotText"/>
        </div>
        <van-row :gutter="10" style="font-size: 12px;">
            <van-col span="6">{{labels[type]}}</van-col>
            <van-col span="9" style="text-align: center;">
                <van-stepper v-if="canAdjust" v-model="price" :min="config.min" :max="config.max"/>
                <template v-else-if="!isFree">{{price | formatCNY}}</template>
            </van-col>
            <van-col span="9">
                <van-button
                    :loading="loading" @click="download" style="height: 28px;"
                    type="primary" size="small" :round="true" :block="true">
                    <template v-if="isFree">免费下载</template>
                    <template v-else-if="total">{{total | formatCNY}} / 答谢下载</template>
                    <template v-else>已答谢，点击下载</template>
                </van-button>
            </van-col>
        </van-row>
        <van-popup class="scan-pay" v-model="payCode.show"
                   :round="true" :close-on-click-overlay="false" :closeable="true">
            <div>使用微信扫码支付</div>
            <img :src="payCode.url" alt=""/>
        </van-popup>
        <van-popup class="mp-code" v-model="mpCode.show"
                   :round="true" :close-on-click-overlay="false" :closeable="true">
            <div>长按识别下载照片</div>
            <ResourceImg :id="mpCode.id"/>
        </van-popup>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import func from "../../../utils/func";
    import DelayLoading from "../../../comps/DelayLoading";
    import WechatJSSDK from "../../../comps/WechatJSSDK";
    import ResourceImg from "../../../comps/ResourceImg";

    export default {
        name: "SaleDownload",
        components: {ResourceImg},
        mixins: [DelayLoading, WechatJSSDK],
        props: {
            user: Object,
            type: Number,
            config: Object,
            photos: Array,
            album: Object,
        },
        data() {
            return {
                price: this.config.init,
                labels: {
                    1: "带水印高清",
                    2: "去水印高清",
                    3: "去水印原图",
                },
                percent: 0,
                failed: false,
                paidPhotos: [],
                mpCode: {
                    id: "",
                    show: false,
                },
                payCode: {
                    url: "",
                    show: false,
                },
                probeTimeId: null,
            }
        },
        computed: {
            total() {
                return this.price * this.needPayPhotos.length;
            },
            isFree() {
                return this.config.init === 0;
            },
            isEnable() {
                return this.config.init >= 0;
            },
            canAdjust() {
                return this.config.min > 0 && this.config.min !== this.config.max;
            },
            pivotText() {
                return this.failed ? "下载失败" : `${this.percent}%`;
            },
            needPayPhotos() {
                return this.photos.filter(photo => {
                    return !func.isDefined(this.paidPhotos[photo]);
                });
            },
        },
        created() {
            this.isEnable && !this.isFree && this.loadPaid();
        },
        destroyed() {
            clearTimeout(this.probeTimeId);
        },
        methods: {
            download() {
                this.isFree ? this.submitDownload() : this.toReward();
            },
            toReward() {
                const self = this;
                if (!this.total) {
                    return self.submitDownload();
                }
                this.withLoading(300, () => {
                    return api.v1.reward.create({
                        "type": self.type,
                        "price": self.price * 100,
                        "photos": self.needPayPhotos,
                        "album_id": self.album.id
                    }).then(res => self.callPay(res.id));
                });
            },
            callPay(rewardId) {
                const self = this;
                let openId = this.user["open_ids"]["ingsports-official-account-service"]
                if (func.isWeiXin() && openId && func.isProd()) {
                    let args = {
                      channel: 11, open_id: openId,
                      app_flag: "mlszp.h5.trade.pay"
                    };
                    return api.v1.reward.toPay(rewardId, args)
                        .then(res => self.pay(res["pay_args"], self.afterPaid)); // JSAPI
                } else {
                    let args = {
                      channel: 15,
                      app_flag: "mlszp.h5.trade.pay"
                    }
                    return api.v1.reward.toPay(rewardId, args) // 扫码支付
                        .then(res => {
                            self.payCode.show = true;
                            self.payCode.url = api.v1.support.qrCode(res["pay_args"]["code_url"], 200);
                            self.probePaid(rewardId); // 探测
                        });
                }
            },
            async probePaid(rewardId) {
                clearTimeout(this.probeTimeId);
                let res = await api.v1.reward.probe(rewardId);
                if (res["is_paid"] === true) {
                    this.payCode.show = false;
                    return this.afterPaid();
                }
                this.probeTimeId = func.setTimeout(2000, () => this.probePaid(rewardId));
            },
            loadPaid() {
                return api.v1.album.photos.reward(this.album.id, this.type).then(res => this.paidPhotos = res);
            },
            afterPaid() {
                this.loadPaid();
                this.submitDownload();
            },
            submitDownload() {
                const self = this;
                this.percent = 0;
                this.failed = false;
                this.withLoading(300, () => {
                    return api.v1.download
                        .create({type: this.type, album_id: this.album.id, photos: this.photos})
                        .then(res => {
                          if (res.album["archived"]) {
                             this.$dialog.alert({message: "请求已提交，系统将在12小时内准备就绪并发送消息通知您下载！"});
                          } else {
                            func.isWeiXin() && func.isProd() ? self.mpCodeLoad(res.id) : self.zipLoad(res.id);
                          }
                        })
                        .catch(() => this.failed = true);
                });
            },
            zipLoad(id) {
                return api.v1.download.zip(id)
                    .then(res => this.downloadWhenReady(res.scene, res.id))
            },
            mpUrlLoad(id) {
                return api.v1.download.mpUrl(id)
                    .then(res => {
                        window.location.href = res.link;
                    });
            },
            mpCodeLoad(id) {
                const self = this;
                return api.v1.download.mpCode(id)
                    .then(res => {
                        self.mpCode.id = res.id;
                        self.mpCode.show = true;
                    });
            },
            downloadWhenReady(scene, handle) {
                const self = this;
                this.percent = Math.min(99, this.percent + Math.ceil(200 / this.photos.length));
                return api.v1.resource.downloadsProbe(scene, handle)
                    .then(res => {
                        if (res["is_ready"] === false) {
                            return func.sleep(2000).then(() => {
                                return self.downloadWhenReady(scene, handle);
                            });
                        }
                        self.percent = 100; // set 100%
                        if (res["available"] === true) {
                            // IOS微信不支持window.open
                            window.location.href = `${process.env.VUE_APP_SUPPORT}/browser/open?url=` + encodeURIComponent(res.url);
                            return Promise.resolve(res.url);
                        }
                        return Promise.reject("package failed");
                    });
            }
        }
    }
</script>

<style scoped lang="less">
    .scan-pay, .mp-code {
        padding: 16px;
        font-size: 16px;
        width: 180px;
        text-align: center;

        img {
            max-width: 100%;
            margin-top: 20px;
        }
    }
</style>

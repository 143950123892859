<template>
    <van-loading
        class="loading" type="spinner" color="#fe5900"
        :vertical="true" size="32px">努力加载中
    </van-loading>
</template>

<script>
    export default {
        name: "SimpleLoading"
    }
</script>

<style scoped lang="less">
    .van-loading__text {
        font-size: 12px;
    }
</style>


<template>
    <van-nav-bar class="live-nav-bar" :left-arrow="true" :fixed="true" v-bind="$attrs" @click-left="back"/>
</template>

<script>
    export default {
        name: "NavBar",
        methods: {
            back() {
                let albumId = this.$route.params.id;
                let noPrev = this.$router.history._startLocation === this.$route.path;
                noPrev ? this.$router.replace({name: "Live.Index", params: {id: albumId}}) : this.$router.back();
            }
        }
    }
</script>

<style lang="less">
    .live-nav-bar.van-nav-bar {
        color: #323233;
        background: #ffffff;

        .van-nav-bar__title {
            color: #323233;
        }

        .van-nav-bar__left {
            font-weight: bold;

            .van-nav-bar__arrow {
                color: #323233;
                font-size: 18px;
            }
        }
    }
</style>

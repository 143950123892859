<template>
    <van-popup class="reward-down" v-model="show" :round="true" :close-on-click-overlay="false" :closeable="true">
        <template v-if="mpCode.show">
            <div class="title">长按识别下载照片</div>
            <div class="code">
                <ResourceImg :id="mpCode.id" :loading="loading"/>
            </div>
        </template>
        <template v-else>
            <div class="title">ZIP打包下载照片</div>
            <div class="zip">
                <van-circle v-model="percent" :rate="percent" :speed="100" :text="text" color="#fe5900" layer-color="#f3f3f3"/>
            </div>
        </template>
    </van-popup>
</template>

<script>
    import api from "../../../utils/api";
    import func from "../../../utils/func";
    import DelayLoading from "../../../comps/DelayLoading";
    import ResourceImg from "../../../comps/ResourceImg";

    export default {
        name: "RewardDown",
        components: {ResourceImg},
        mixins: [DelayLoading],
        data() {
            return {
                show: false,
                failed: false,
                percent: 0,
                mpCode: {
                    id: "",
                    show: func.isWeiXin() && func.isProd(),
                },
            }
        },
        computed: {
            text() {
                return this.failed ? "下载失败" : this.percent.toFixed(0) + '%';
            }
        },
        methods: {
            start(albumId, type, photos) {
                const self = this;
                self.show = true;
                self.percent = 0;
                self.failed = false;
                self.mpCode.id = "";
                this.withLoading(300, () => {
                    return api.v1.download
                        .create({type: type, album_id: albumId, photos: photos})
                        .then(res => func.isWeiXin() && func.isProd() ? self.mpCodeLoad(res.id) : self.zipLoad(res.id))
                        .catch(err => {
                            self.failed = true;
                            console.log(err); // debug
                        });
                });
            },
            zipLoad(id) {
                const self = this;
                return api.v1.download.zip(id)
                    .then(res => self.downloadWhenReady(res.scene, res.id, res.config.items.length))
            },
            mpUrlLoad(id) {
                return api.v1.download.mpUrl(id).then(res => window.location.href = res.link);
            },
            mpCodeLoad(id) {
                return api.v1.download.mpCode(id)
                    .then(res => this.mpCode.id = res.id);
            },
            downloadWhenReady(scene, handle, length) {
                const self = this;
                this.percent = Math.min(99, this.percent + Math.ceil(200 / length));
                return api.v1.resource.downloadsProbe(scene, handle)
                    .then(res => {
                        if (res["is_ready"] === false) {
                            return func.sleep(2000).then(() => {
                                return self.downloadWhenReady(scene, handle, length);
                            });
                        }
                        self.percent = 100; // set 100%
                        if (res["available"] === true) {
                            // IOS微信不支持window.open
                            window.location.href = `${process.env.VUE_APP_SUPPORT}/browser/open?url=` + encodeURIComponent(res.url);
                            return Promise.resolve(res.url);
                        }
                        return Promise.reject("package failed");
                    });
            }
        }
    }
</script>


<style scoped lang="less">
    .reward-down {
        width: 220px;
        padding: 0 16px;

        .title {
            padding: 16px 0;
            font-size: 16px;
            text-align: center;
        }

        .zip, .code {
            width: 100%;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
</style>

<template>
    <div class="fixed-func">
        <div class="menu" :style="{left:fixedLeft+'px'}" v-show="!selectable">
            <van-button type="primary" size="small" icon="user-o" :round="true" @click="toUCenter">我的</van-button>
            <van-button v-if="customerBook && !download" type="primary" size="small" icon="edit" :round="true" @click="openBook">合作</van-button>
            <van-button v-if="!empty" type="primary" size="small" icon="apps-o" :round="true" @click="openPuzzle">拼图</van-button>
            <van-button v-if="download && !empty" type="primary" size="small" icon="cart-o" :round="true" @click="toProduct">产品</van-button>
            <van-button v-if="download && !empty" type="primary" size="small" icon="down" :round="true" @click="toDownload">下载</van-button>
        </div>
        <div class="desc" v-show="selectable">
            <label class="all" v-if="!limit">
                <input type="checkbox" v-model="selectAll"/> 全选
            </label>
            已选择 {{selected.length}} 张
            <span v-if="limit">，最多选择 {{limit}} 张</span>
            <van-button type="primary" size="small" icon="success"
                        :disabled="!enable" :round="true" @click="submit">确认
            </van-button>
            <van-button type="default" size="small" icon="cross" :round="true" @click="cancel">取消</van-button>
        </div>
        <!--用户登录-->
        <LoginPopup ref="loginPopup"/>
        <!--合作预约-->
        <Book ref="book" :album="album"/>
        <!--拼图类型-->
        <PuzzleType ref="puzzleType" @click="puzzleDone"/>
        <!--拼图结果-->
        <PuzzleShow ref="puzzleShow" @close="cancel"/>
        <!--下载进度-->
        <DownloadPg ref="downloadPg" :album="album" @close="cancel"/>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import log from "../../../utils/log";
    import auth from "../../../utils/auth";
    import PuzzleType from "./PuzzleType";
    import PuzzleShow from "./PuzzleShow";
    import DelayLoading from "../../../comps/DelayLoading";
    import DownloadPg from "./DownloadPg";
    import LoginPopup from "../../../comps/LoginPopup";
    import Book from "./Book";
    import func from "../../../utils/func";

    export default {
        name: "FixedFunc",
        components: {Book, LoginPopup, DownloadPg, PuzzleShow, PuzzleType},
        mixins: [DelayLoading],
        props: {
            album: Object,
            selected: Array,
            empty: Boolean,
            download: Boolean,
        },
        data() {
            return {
                least: 0,
                limit: 0,
                submitType: "",
                selectAll: false,
                selectable: false,
            }
        },
        computed: {
            enable() {
                return this.selected.length > 0
                    && (this.least === 0 || this.selected.length >= this.least);
            },
            fixedLeft() {
                return func.maxBodyWith() - 85;
            },
            customerBook() {
                return this.album.config.function['customer_book'];
            }
        },
        watch: {
            selectAll(value) {
                this.$emit("selectAll", value);
            },
            selectable(value) {
                this.$emit("select", value, this.limit);
            }
        },
        methods: {
            openPuzzle() {
                this.$refs.puzzleType.open();
            },
            toUCenter() {
                this.$refs.loginPopup.open(() => this.$router.push({name: "UCenter"}));
            },
            toDownload() {
                const self = this;
                this.$refs.loginPopup.open(() => {
                    self.limit = 0;
                    self.least = 0;
                    self.selectable = true;
                    self.submitType = "download";
                });
            },
            puzzleDone(e) {
                this.limit = 9;
                if (e === "gif") {
                    this.least = 2;
                }
                this.submitType = e;
                this.selectable = true;
            },
            cancel() {
                this.limit = 0;
                this.least = 0;
                this.submitType = "";
                this.selectAll = false;
                this.selectable = false;
            },
            submit() {
                let isDownload = this.submitType === "download";
                return isDownload ? this.doDownload() : this.doPuzzle();
            },
            doPuzzle() {
                const self = this;
                this.$refs.puzzleShow.open(() => {
                    log.image("album.puzzle", {album_id: self.album.id, type: self.submitType});
                    return api.v1.album.puzzle[self.submitType](self.album.id, self.selected);
                });
            },
            doDownload() {
                this.$refs.downloadPg.open(this.selected);
            },
            toProduct() {
                let type = this.$route.params.type;
                let value = this.$route.params.value;
                auth.getToken();
                window.location.href = `//m.mlszp.com/#/efoto/${this.album.id}/${type}/${value}`;
            },
            openBook() {
                this.$refs.book.open();
            }
        }
    }
</script>

<style scoped lang="less">
    .fixed-func {
        .menu {
            width: 80px;
            bottom: 30px;
            z-index: 1000;
            position: fixed;

            .van-button {
                margin-top: 15px;
                border-width: 2px;
                border-color: #fff;
            }
        }

        .desc {
            bottom: 0;
            color: #333;
            width: 100%;
            padding: 12px 0;
            max-width: 720px;
            z-index: 1000;
            font-size: 12px;
            position: fixed;
            background: #fff;
            line-height: 36px;
            text-indent: 13px;

            .all {
                float: left;
                text-indent: 3px;
                padding-left: 15px;

                input {
                    width: 20px;
                    height: 20px;
                    margin: 7px 0;
                    float: left;
                }
            }

            .van-button {
                float: right;
                margin-right: 12px;
                letter-spacing: 2px;
            }
        }

        .van-button--small {
            height: 36px;
            padding: 0 10px;
        }
    }
</style>
